$displayViews-h1-color: $token-color-base-dark-gray;
$displayViews-markdownContent-padding-top: $token-spacing-0 !important;
$displayViews-MDTOCSectionWrapper-padding-top: $token-spacing-4;
$displayViews-leftSideColumn-top: $token-spacing-18;
$displayViews-leftSideColumn-padding-top: $token-spacing-4;
$displayViews-leftSideColumn-padding-right: $token-spacing-0;
$displayViews-markdown-anchor-padding-top: $token-spacing-24 !important;
$displayViews-markdown-anchor-margin-top: -$token-spacing-24 !important;
$displayViews-leftSideGrid-display: flex;
$displayViews-leftSideColumn-flex: 0 0 $token-spacing-280px;
$displayViews-markdownContent-flex: 1;
$displayViews-markdownContent-width: 70%;
$displayViews-MDTOCSectionWrapper-MDTOCSectionContent-margin-top: $token-spacing-85px;
$displayViews-TOCMenuList-a-word-break: break-word;
$displayViews-MDTOCSectionWrapper-flex: 0 0 $token-spacing-228px;
$displayViews-markdownContent-p-word-break: break-word;
$displayViews-markdownContent-td-word-break: keep-all;
$displayViews-markdownContent-table-td-word-break: break-word;
$displayViews-redocDiv-padding-left: $token-spacing-0 !important;